<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="certifications"
        :items-per-page="-1"
        :search="searchCertification"
        :loading="loadingData"
        item-key="id"
        sort-by="id"
        disable-sort
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title class="text-subtitle-1 text-md-h5"
              >Certificaciones</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-certification"
                label="Buscar.."
                name="findcertification"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchCertification"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog
              :fullscreen="$vuetify.breakpoint.smAndDown"
              v-model="dialog"
              max-width="600px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :small="$vuetify.breakpoint.smAndDown"
                  color="blue darken-1"
                  dark
                  depressed
                  elevation="0"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="newItem"
                >
                  Adicionar
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="text-h6 grey lighten-2">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Nombre - Español"
                            :rules="nameRules"
                            required
                          ></v-text-field>

                          <v-text-field
                            v-model="editedItem.name_english"
                            label="Nombre - Inglés"
                            :rules="nameRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-btn color="primary" rounded outlined>
                            <v-icon left> mdi-translate </v-icon>
                            Traducción
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="primary"
                      text
                      submit
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >Esta seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{ item }">
          <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="addImage(item)"
              >
                mdi-camera
              </v-icon>
            </template>
            <span>Imagen</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getCertifications"> Recargar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>

    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogImage" persistent width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn class="hidden-sm-and-down" icon dark @click="dialogImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Administrar Imagen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogImage = false">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fill-height bottom-gradient fluid class="ma-0">
          <v-row>
            <v-col col="6">
              <div class="fill-height">
                <!--v-model="model"-->
                <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                               prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                  <v-slide-item key="qr" v-slot="{ active, toggle }" v-if="editedItem.image_certification">
                    <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="auto"
                            width="250" elevation="5" @click="toggle">
                      <v-img :src="
                                  editedItem.image_certification
                                    ? editedItem.image_certification
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="250"
                             class="rounded-lg white--text align-end justify-start text-end elevation-10">
                        <v-btn dark icon elevation="0" class="ma-2"
                               v-on:click="deleteImage">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="container mt-0 pt-0">
          <v-file-input v-model="selectedFiles" :disabled="selectedFiles.length>0" small-chips accept="image/png, image/jpeg, image/bmp" show-size
                        multiple clearable label="Agregar imagen" @change="inputChanged">
            <template v-slot:selection="{ index, text }">
              <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <div v-if="imageFiles">
            <h5>Archivos seleccionados</h5>
            <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
              {{ f.name }}
            </v-chip>
            <div class="py-3">
              <v-btn dark color="primary" @click="uploadPictures()">Actualizar</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import certificationsService from "@/providers/CertificationsService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "CertificationsComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    valid: true,
    selectTranslation: { id: 1, text: "Elemento 1" },
    translations: [],
    searchCertification: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    certifications: [],
    imageFiles: [],
    selectedFiles: [],
    dialogImage: false,
    headers: [
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Traducción", value: "translation_id", align: " d-none" },
      { text: "Acciones", value: "actions", width: 100, sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      name_english: "",
      translation_id: null,
      image_certification: null,
    },
    defaultItem: {
      id: 0,
      name: "",
      name_english: "",
      translation_id: null,
      image_certification: null,
    },
    nameRules: [
      (v) => !!v || "El Nombre es obligatorio!",
      // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Certificación"
        : "Editar Certificación";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    addImage(item){
      this.editedItem = item;
      this.imageFiles = [];
      this.selectedFiles = [];
      this.dialogImage = true;
    },
    deleteImage(){
      certificationsService
          .deleteImage({id: this.editedItem.id})
          .then(() => {
            this.snackText = "Imagen eliminada al servidor. Galería actualizada!";
            this.editedItem.image_certification = null;
          }).catch(()=>{
            this.snackText = "Un error impidió eliminar la imagen al servidor!"
          }).finally(()=>{
            this.snackBar = true;
          });
    },
    uploadPictures() {
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        certificationsService
            .uploadImage(this.editedItem.id, this.imageFiles[i])
            .then((resp) => {
              this.editedItem.image_certification = resp.value;
              this.imageFiles = [];
              this.selectedFiles = [];
              this.snackText = "Imagen subida al servidor. Galería actualizada!";
            }).catch(()=>{
              this.snackText = "Un error impidió subir al menos una imágen al servidor!"
            }).finally(()=>{
              this.snackBar = true;
            });
      }
    },
    inputChanged() {
      this.imageFiles = [...this.selectedFiles];
    },
    removeChip(index) {
      this.imageFiles.splice(index, 1);
      this.selectedFiles = [...this.imageFiles];
    },
    deleteItemConfirmPicture() {
      certificationsService
          .deleteImage(
              this.currentPicture.pivot.doctor_id,
              this.currentPicture.id
          )
          .then((result) => {
            this.closeDeletePicture();
            if (result.success) {
              this.snackText = "Imagen eliminada desde la galería!";
              this.loadGalleryImages(this.editedItem);
            } else {
              this.snackText =
                  "Un error impidió eliminar la imágen desde la galería!";
            }
            this.snackBar = true;
          });
    },
    getCertifications() {
      // carga los datos desde el proveedor de datos.
      this.loadingData = true;
      certificationsService.getAllRecords().then((record) => {
        this.certifications = record.value;
        // console.log("Certificaciones: ", this.certifications);
        this.loadingData = false;
      });
    },
    newItem() {
      this.valid = false;
    },
    editItem(item) {
      this.editedIndex = this.certifications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.certifications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.certifications.splice(this.editedIndex, 1);
      certificationsService.deleteRecord(this.editedItem.id);
      // console.log('Item deleted:', this.editedItem);
      this.closeDelete();
      this.snackText = "Registro eliminado con éxito.";
      this.snackBar = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.editedIndex > -1) {
          Object.assign(this.certifications[this.editedIndex], this.editedItem);
          certificationsService.updateRecord(
            this.editedItem.id,
            this.editedItem
          );
        } else {
          this.certifications.push(this.editedItem);
          certificationsService.addRecord(this.editedItem);
        }
        this.close();
        this.snackText = "Datos del formulario guardado con éxito...";
        this.snackBar = true;
        this.getCertifications();
      }
    },
  },
  mounted() {
    this.certifications = [];
    this.getCertifications();
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
